@import '../../../shared/stylesheets/variables';

.filter-section {
  margin-bottom: 32px;

  .accordion-container {
    background: $colWhite;
    border-radius: 4px;
    min-height: 68px;
    width: 100%;

    &.accordion-disabled {
      background: $colWhite;
      cursor: not-allowed;
    }
  }

  .accordion-summary-container {
    border-radius: 4px;
    cursor: pointer;
    min-height: 68px !important;
    padding: 0 20px;

    &.accordion-summary-disabled {
      opacity: 1;
    }
  }

  .accordion-summary-content {
    color: $colPrimary;
    font-size: 20px;
    font-weight: 500;
    justify-content: space-between;
    line-height: 26px;
    margin: 0;
  }

  .accordion-summary-content.accordion-summary-content-expanded {
    margin: 0;
  }

  .filter-title {
    display: flex;
    text-transform: capitalize;

    > * {
      margin-bottom: auto;
      margin-top: auto;
    }

    .field-changed-container {
      color: $colGrey;
      display: inline-block;

      &:before {
        background-color: $colGrey;
        content: '';
        display: inline-block;
        height: 2px;
        margin: 6px 6px 6px 12px;
        width: 16px;
      }
    }
  }

  .collapse-btn {
    display: flex;
    font-size: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;

    > * {
      margin-bottom: auto;
      margin-top: auto;
    }

    .collapse-span {
      margin-left: 20px;
    }
  }

  .accordion-details-container {
    border-radius: 0 0 4px 4px;
    padding: 40px;
    width: 100%;
  }
}
