.layout-container {
  box-sizing: border-box;
  position: relative;
  width: 100vw;
  height: 100vh;
  padding-left: 260px;

  .tool-bar {
    justify-content: flex-end;
  }
}

.drawer {
  width: 260px;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14);
}

.drawer-paper {
  width: 260px;
  background: #303f9f !important;
}

.drawer-content-container {
  color: #fff;

  .menu-title {
    font-size: 20px;
    font-weight: 500;
    padding: 24px 0 16px 24px;

    > div {
      margin-bottom: 8px;
    }

    .site-title {
      font-size: 12px;
    }
  }

  .menu-item {
    height: 56px;
    border-top: solid 1px rgba(255, 255, 255, 0.3);
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 16px;

    &:last-of-type {
      border-bottom: solid 1px rgba(255, 255, 255, 0.3);
    }

    .item-icon {
      margin: 0 24px;
    }
  }

  .menu-item:active {
    background: rgba(159, 168, 218, 0.3);
  }

  .feedbacks {
    height: 56px;
    border-top: solid 1px rgba(255, 255, 255, 0.3);
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0;

    .item-icon {
      margin: 0 24px;
    }

    .expand-icon {
      margin-right: 24px;
      margin-left: 70px;
      width: 24px;
      height: 25px;
    }
  }

  .feedback-item {
    padding-left: 72px;
    line-height: 24px;
    letter-spacing: 0.1px;
    font-family: 'Roboto';
    font-weight: 500;
  }

  .select {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    padding-right: 32px !important;
    font-family: 'Roboto';
  }

  .icon {
    color: #fff;
  }

  .nested {
    padding-left: 4px;
  }

  .add-icon {
    margin-left: auto;
  }

  .filter-item {
    width: 80%;
  }

  .clear-icon {
    margin-left: auto;
  }
}
