// Color
$colBlack: #000;
$colGrey: #9a9a9a;
$colPrimary: #303f9f;
$colWhite: #fff;

// Shadow
$shadowBox: 0 1px 5px 0 rgba($colBlack, 0.2), 0 3px 1px -2px rgba($colBlack, 0.12), 0 2px 2px 0 rgba($colBlack, 0.14);

// Form field
$fieldHeight: 46px;

// zIndex
$dateRange: 20;
