.action-button {
  background-color: #303f9f;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 7px;

  .header-activate-categories {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .header-hint {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 16px;
    }
  }

  .header-archived-categories {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    font-size: 16px;

    .header-title {
      font-size: 24px;
    }
  }
}
